import React, { ReactNode, useState } from "react";
import { ViewIcon, HideIcon } from "@shopify/polaris-icons";
import { Button, TextField, Spinner } from "@shopify/polaris";
import FormErrorMessage from "../FormErrorMessage";
import { getPasswordError } from "../../utils/formUtils";
import { Formik } from "formik";
import FamilyImage from "../../assets/consult/consult-family-illustration.png";
import { css, StyleSheet } from "aphrodite";
import { useActions } from "tiny-atom/react/hooks";
import { logEvent } from "../../actions";
import { TouchableOpacity } from "../react-native";
import ButtonComponent from "../Button";
import Text from "../core/Text";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import Flex from "../core/Flex";

type Props = {
  onPasswordSubmitted: (value: string | undefined) => Promise<void>;
  consultPath: string;
  progressComponent?: ReactNode;
};

export default function CareScreenerPasswordInput({ onPasswordSubmitted, consultPath, progressComponent }: Props) {
  const { setConsultCallback, navigate } = useActions();
  const { widthStyle } = useCareScreenerWidth();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);

  const validateForm = () => {
    const errors: { password?: string } = {};
    const error = getPasswordError(password);
    if (error) {
      errors.password = error;
    }
    return errors;
  };

  const submit = () => {
    setLoading(true);
    onPasswordSubmitted(password).then(() => {
      setLoading(false);
    });
  };

  return (
    <Flex className={css(styles.container)} style={widthStyle}>
      {progressComponent}
      <div className={css(styles.columnCentered)}>
        <img src={FamilyImage} className={css(styles.image)} />
        <Text style={styles.title} theme="manaBlueDark" title size={24}>
          Let's get you care!
        </Text>
        <Text style={styles.subtitle} theme="manaBlue" weight={700}>
          Create your Manatee account to match with a provider.
        </Text>
        <div className={css(styles.formContainer)}>
          <Formik initialValues={{ password: "" }} onSubmit={submit} validate={validateForm}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="createPasswordForm">
                <FormErrorMessage name="password" component="div" />
                <div className={css(styles.paddingTop)}>
                  <TextField
                    placeholder="password"
                    onChange={setPassword}
                    type={hidePassword ? "password" : "text"}
                    name="password"
                    value={password}
                    label=""
                    autoComplete="off"
                  />
                  <div className={css(styles.showHidePassword)}>
                    <Button
                      onClick={() => setHidePassword(!hidePassword)}
                      variant="plain"
                      size="slim"
                      icon={hidePassword ? ViewIcon : HideIcon}
                    ></Button>
                  </div>
                </div>
                <div className={css(styles.paddingTop, styles.paddingBottom)}>
                  <ButtonComponent
                    small
                    type="submit"
                    theme="manaBlue"
                    disabled={loading}
                    loading={loading}
                    onPress={handleSubmit}
                    title="submit"
                  ></ButtonComponent>
                </div>
                <p className={css(styles.subtitleGray, styles.font14)}>Already have Manatee?</p>
                <p className={css(styles.subtitleGray, styles.font14)}>Enter your existing password.</p>
                {loading ? (
                  <div className={css(styles.spinnerContainer)}>
                    <Spinner size="small" />
                  </div>
                ) : (
                  <TouchableOpacity
                    style={{ cursor: "pointer" }}
                    onPress={() => {
                      logEvent("careScreenerResetPassword");
                      return setConsultCallback({
                        params: {
                          consultCallback: password => {
                            onPasswordSubmitted(password);
                            navigate({ pathname: `/${consultPath}` });
                          }
                        }
                      });
                    }}
                  >
                    <Text
                      center
                      link={{
                        onPress: () => {
                          logEvent("careScreenerResetPassword");
                          return setConsultCallback({
                            params: {
                              consultCallback: password => {
                                onPasswordSubmitted(password);
                                navigate({ pathname: `/${consultPath}` });
                              }
                            }
                          });
                        }
                      }}
                      style={styles.forgotPassword}
                    >
                      Forgot password?
                    </Text>
                  </TouchableOpacity>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Flex>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
    marginBottom: 50,
    padding: "5%",
    paddingTop: 10,
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15
  },
  columnCentered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  image: {
    width: 300,
    height: 150,
    minHeight: 160,
    objectFit: "contain",
    aspectRatio: 1,
    marginTop: 20,
    marginBottom: 10
  },
  title: {
    textAlign: "center",
    marginBottom: 10,
    marginTop: 20
  },
  subtitle: {
    textAlign: "center",
    marginBottom: 10,
    width: 300,
    alignSelf: "center",
    lineHeight: 1.5
  },
  underline: {
    textDecoration: "underline"
  },
  subtitleGray: {
    fontFamily: "Helvetica",
    color: "#636C8C",
    fontSize: 18,
    lineHeight: 1.4,
    fontWeight: 500,
    textAlign: "center"
  },
  forgotPassword: {
    textAlign: "center",
    fontSize: 14,
    lineHeight: 1.4,
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 400
  },
  resetPasswordButton: {
    ":nth-child(1n) > button": {
      width: "148px"
    }
  },
  formContainer: {
    width: 250
  },
  paddingTop: {
    paddingTop: 15,
    position: "relative"
  },
  paddingBottom: {
    paddingBottom: 15
  },
  font14: {
    fontSize: 14
  },
  showHidePassword: {
    position: "absolute",
    top: "1rem",
    right: "0",
    zIndex: 40
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "55px"
  }
});
