import React from "react";
import { StyleSheet, css } from "aphrodite";
import ManateeLogo from "../assets/manatee-logo-small-text.png";
import FishAnimation from "../assets/fish-jumping.gif";

type Props = {
  disableShadow?: boolean;
};

export default function ManateeLoading({ disableShadow }: Props) {
  return (
    <div className={css(styles.container, !disableShadow ? styles.shadow : undefined)}>
      <div className={css(styles.relative)}>
        <img src={FishAnimation} className={css(styles.fishAnimation)} />
        <img src={ManateeLogo} className={css(styles.manateeLogoAbsolute)} />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    height: 260
  },
  shadow: {
    boxShadow: "4px 4px 23px -6px rgba(0, 0, 0, 0.25)"
  },
  relative: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    bottom: 20
  },
  fishAnimation: {
    height: 360,
    objectFit: "contain",
    paddingBottom: 60
  },
  manateeLogoAbsolute: {
    width: 180,
    height: 180,
    position: "absolute",
    objectFit: "contain",
    bottom: 40,
    left: "auto",
    right: "auto"
  }
});
