import React, { useState } from "react";
import { Candidate, Candidates } from "../services/CareScreenerService";
import useArrayIterator, { ArrayIterator } from "../hooks/useArrayIterator";

type CareScreenerContext = {
  candidates: Candidates;
  setCandidates: (candidates: Candidates) => void;
  candidateIterator: ArrayIterator<Candidate>;
};

export const CareScreenerContext = React.createContext<CareScreenerContext | undefined>(undefined);

export function CareScreenerContextProvider({ children }) {
  const [candidates, setCandidates] = useState<Candidates>([]);
  const candidateIterator = useArrayIterator(candidates);

  return (
    <CareScreenerContext.Provider
      value={{
        candidates,
        setCandidates,
        candidateIterator
      }}
    >
      {children}
    </CareScreenerContext.Provider>
  );
}
