import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import ManateeLoading from "../ManateeLoading";

export type LoadingTextVariant = { text: string; subText?: string };

type Props = {
  onTextRotation: () => void;
  textVariants: LoadingTextVariant[];
};

export default function CareScreenerLoading({ onTextRotation, textVariants }: Props) {
  const [textIndex, setTextIndex] = useState(0);
  const intervalMillis = useRef<number>(3000);
  const onTextRotationRef = useRef<() => unknown>();
  const textVariantsRef = useRef<LoadingTextVariant[]>();

  onTextRotationRef.current = onTextRotation;
  textVariantsRef.current = textVariants;

  useEffect(() => {
    const variants = textVariantsRef.current || [];
    const interval = setInterval(() => {
      if (onTextRotationRef.current) {
        onTextRotationRef.current();
      }
      setTextIndex(index => {
        if (index < variants.length - 1) {
          return index + 1;
        }
        intervalMillis.current = 500;
        return index;
      });
    }, intervalMillis.current);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ManateeLoading disableShadow />
      <div className={css(styles.textContainer)}>
        {!!(textVariantsRef.current && textVariantsRef.current[textIndex]?.text) && (
          <p className={css(styles.text)}>{textVariantsRef.current[textIndex].text}</p>
        )}
        {!!(textVariantsRef.current && textVariantsRef.current[textIndex]?.subText) && (
          <p className={css(styles.subText)}>{textVariantsRef.current[textIndex].subText}</p>
        )}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  textContainer: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "center"
  },
  text: {
    fontWeight: 700,
    color: "#1A2579",
    fontSize: 20,
    lineHeight: 1.1
  },
  subText: {
    fontWeight: 400,
    color: "#1A2579",
    fontSize: 16,
    paddingTop: 20,
    lineHeight: 1.1
  }
});
