import React from "react";
import { StyleSheet, css } from "aphrodite";
import Flex from "../core/Flex";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import CareScreenerLoading from "./CareScreenerLoading";

export default function AccountCreationLoading({ progressComponent, textVariants, onTextRotation }) {
  const { widthStyle } = useCareScreenerWidth();
  return (
    <Flex flexShrink center className={css(styles.container)} style={widthStyle}>
      {progressComponent}
      <CareScreenerLoading textVariants={textVariants} onTextRotation={onTextRotation} />
    </Flex>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
    marginBottom: 50,
    padding: "5%",
    paddingTop: 10,
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15
  }
});
