import gql from "graphql-tag";

export const HOME = gql`
  {
    me {
      id
      accountType
      firstName
      lastName
      providerName
    }
    assessments {
      identifier
      name
      type
      categoryCriteria {
        order
        category
        clinicalCategory
        minScore
        maxScore
        color
      }
      subAssessments {
        identifier
        name
        type
        categoryCriteria {
          order
          category
          clinicalCategory
          minScore
          maxScore
          color
        }
      }
    }
    canReceiveMessages {
      canReceiveMessages
    }
    messageTemplates {
      id
      key
      name
      category
      message
    }
  }
`;

export const GET_CHILDREN = gql`
  {
    me {
      children {
        user {
          id
          accountType
          firstName
          lastName
          dateOfBirth
          pointsBalance
          createdAt
          username
          email
          lastActiveAt
          timeZone
          familyId
          ehrUserId
          parents {
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const THERAPIST_GET_CUSTOM_GOALS = gql`
  {
    therapistCustomGoals {
      id
      name
      value
      imageId
    }
  }
`;
export const THERAPIST_GET_CUSTOM_PARENT_GOALS = gql`
  {
    goals(options: { includeAssignable: true, assigneeTypes: ["PARENT"] }) {
      id
      name
      description
      dueAt
      assignedAt
      reminderTime1
      reminderTime2
    }
  }
`;

export const THERAPIST_GET_MOOD = gql`
  query ($childId: Int!, $daysBack: Int) {
    childMood(childId: $childId, daysBack: $daysBack) {
      sadCount
      neutralCount
      happyCount
    }
  }
`;

export const THERAPIST_GET_CHILD = gql`
  query ($childId: Int!) {
    childDetails(childId: $childId) {
      id
      accountType
      firstName
      lastName
      lifetimePoints
      createdAt
      therapists {
        user {
          id
        }
      }
    }
    childParents(childId: $childId) {
      id
      firstName
      lastName
      email
      createdAt
      lastActiveAt
      timeZone
      accountType
      ehrUserId
      goals {
        id
        name
        description
        dueAt
        assignedAt
        reminderTime1
        reminderTime2
        achievements(options: { excludePending: true }) {
          id
          achievedAt
        }
      }
      achievements(options: { excludePending: true }) {
        id
        achievedAt
        goalFeedback
        feelingDuringCompletion
        feedbackText
        goal {
          id
          name
        }
      }
    }
    childGoals(childId: $childId, options: { includeFinalized: true }) {
      id
      name
      dueAt
      dailyFrequency
      expiresAt
      finalizedAt
      value
      assignedAt
      assignerType
      reminderTime1
      achievements(childId: $childId, options: { excludePending: true }) {
        id
        achievedAt
        points
        image
      }
    }
    achievements(childId: $childId, options: { excludePending: true }) {
      id
      achievedAt
      points
      goalFeedback
      feelingDuringCompletion
      feedbackText
      goal {
        id
        name
        dueAt
        assignedAt
        expiresAt
      }
    }
    rewards(childId: $childId) {
      id
      name
      redemptions {
        approvedBy {
          id
        }
        redeemedAt
        cost
      }
    }
  }
`;

export const GET_ASSESSMENT_ASSIGNMENTS_AND_COMPLETIONS = gql`
  query ($userIds: [Int], $assessmentIdentifiers: [String]) {
    assessmentsAssignable(userIds: $userIds) {
      assessmentIdentifiers
      assignable
      userAssessments {
        userId
        assessmentIdentifiers
      }
    }
    assessmentAssignments(assigneeIds: $userIds) {
      assigneeId
      assessmentIdentifier
      assessmentParameters {
        subjectUserId
      }
    }
    completedAssessments(options: { userIds: $userIds, assessmentIdentifiers: $assessmentIdentifiers }) {
      id
      clientId
      assessmentIdentifier
      category
      score
      scorePossible
      completedAt
      name
      answers {
        questionId
        choiceId
        questionText
        choiceText
        responseText
      }
      assessmentParameters {
        subjectUserId
      }
      subResponses {
        id
        clientId
        assessmentIdentifier
        category
        score
        scorePossible
        completedAt
        name
        answers {
          questionId
          choiceId
          questionText
          choiceText
        }
        assessmentParameters {
          subjectUserId
        }
      }
    }
  }
`;

export const ASSIGN_ASSESSMENTS = gql`
  mutation ($assignments: [AssessmentAssignmentRequest!]) {
    assignAssessments(assignments: $assignments) {
      assigneeId
    }
  }
`;

export const ADULT_GET_REWARDS = gql`
  query ($childId: Int!) {
    childDetails(childId: $childId) {
      id
      firstName
    }
    rewards(childId: $childId) {
      id
      name
      description
      imageId
      cost
      assignedTo {
        id
      }
    }
  }
`;

export const ADULT_GET_GOALS = gql`
  query ($childId: Int!) {
    childDetails(childId: $childId) {
      id
      accountType
      firstName
      theme
    }
    adultGoals(childId: $childId) {
      id
      name
      description
      dueAt
      dailyFrequency
      reminderTime1
      reminderTime2
      expiresAt
      value
      imageId
      assignedTo {
        id
      }
      readOnly
      assignerType
    }
  }
`;

export const GET_GOAL_ACHIEVEMENT_IMAGE = gql`
  query ($assigneeId: Int!, $goalId: Int!, $achievementId: Int!) {
    achievementImage(assigneeId: $assigneeId, goalId: $goalId, achievementId: $achievementId) {
      image
    }
  }
`;

export const THERAPIST_CREATE_GOAL = gql`
  mutation (
    $assignedTo: Int!
    $name: String!
    $description: String
    $dueAt: String!
    $dailyFrequency: Int
    $reminderTime1: String
    $reminderTime2: String
    $expiresAt: String!
    $value: Int!
    $imageId: Int!
    $createUnassignedCopy: Boolean
    $unassignedCopyReminderTime1: String
    $unassignedCopyReminderTime2: String
  ) {
    createGoal(
      name: $name
      description: $description
      dueAt: $dueAt
      dailyFrequency: $dailyFrequency
      reminderTime1: $reminderTime1
      reminderTime2: $reminderTime2
      expiresAt: $expiresAt
      assignedTo: $assignedTo
      value: $value
      imageId: $imageId
      createUnassignedCopy: $createUnassignedCopy
      unassignedCopyReminderTime1: $unassignedCopyReminderTime1
      unassignedCopyReminderTime2: $unassignedCopyReminderTime2
    ) {
      id
    }
  }
`;

export const THERAPIST_UPDATE_GOAL = gql`
  mutation (
    $goalId: Int!
    $name: String!
    $description: String
    $dueAt: String!
    $dailyFrequency: Int
    $reminderTime1: String
    $reminderTime2: String
    $value: Int!
    $imageId: Int!
  ) {
    updateGoal(
      goalId: $goalId
      name: $name
      description: $description
      dueAt: $dueAt
      dailyFrequency: $dailyFrequency
      reminderTime1: $reminderTime1
      reminderTime2: $reminderTime2
      value: $value
      imageId: $imageId
    ) {
      id
    }
  }
`;

export const DELETE_GOAL = gql`
  mutation ($goalId: Int!) {
    deleteGoal(goalId: $goalId)
  }
`;

export const RENEW_GOAL = gql`
  mutation ($goalId: Int!, $childId: Int!, $newExpirationDate: String!) {
    renewGoal(goalId: $goalId, childId: $childId, newExpirationDate: $newExpirationDate) {
      id
      expiresAt
    }
  }
`;

export const FINALIZE_GOAL = gql`
  mutation ($goalId: Int!, $childId: Int!) {
    finalizeGoal(childId: $childId, goalId: $goalId) {
      id
      finalizedAt
    }
  }
`;

export const COMPLETE_AND_VERIFY_GOAL = gql`
  mutation completeAndVerifyGoal($goalId: Int!, $childId: Int!, $note: String, $achievedDate: String) {
    completeAndVerifyGoal(goalId: $goalId, childId: $childId, note: $note, achievedDate: $achievedDate) {
      id
    }
  }
`;

export const THERAPIST_INVITE_USER = gql`
  mutation (
    $email: String!
    $note: String
    $language: String!
    $accountType: String!
    $clientName: String!
    $primaryTherapistId: Int
  ) {
    therapistInviteUser(
      email: $email
      note: $note
      language: $language
      accountType: $accountType
      clientName: $clientName
      primaryTherapistId: $primaryTherapistId
    )
  }
`;

export const THERAPIST_RESEND_INVITE = gql`
  mutation ($inviteId: Int!, $note: String) {
    therapistResendInviteUser(inviteId: $inviteId, note: $note)
  }
`;

export const THERAPIST_DELETE_INVITE = gql`
  mutation ($inviteId: Int!) {
    therapistDeleteInviteUser(inviteId: $inviteId)
  }
`;

export const THERAPIST_GET_INVITES = gql`
  {
    therapistInvites {
      inviteId
      email
      accountType
      clientName
    }
  }
`;

export const STORE_PENDING_INVITE_GOALS = gql`
  mutation ($inviteId: Int!, $goals: [PendingGoal], $timeZoneOffset: String!) {
    storePendingInviteGoals(inviteId: $inviteId, goals: $goals, timeZoneOffset: $timeZoneOffset)
  }
`;

export const THERAPIST_LINK_EXISTING = gql`
  mutation ($parentEmail: String!, $language: String!, $primaryTherapistId: Int) {
    therapistLinkExistingFamily(
      parentEmail: $parentEmail
      language: $language
      primaryTherapistId: $primaryTherapistId
    ) {
      id
      firstName
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation ($options: SendMessageOptions!) {
    sendMessage(options: $options)
  }
`;

export const GET_CONVERSATIONS = gql`
  query ($options: ConversationQueryOptions!) {
    conversations(options: $options) {
      id
      read
      users {
        id
        firstName
        lastName
        accountType
        familyId
      }
      messages {
        id
        from {
          id
          firstName
          lastName
        }
        message
        date
        automated
        hasAttachments
      }
      recipientReadAt
      type
      familyId
      pendingActions {
        id
        reason
      }
      permissions
    }
  }
`;

export const MESSAGE_ATTACHMENTS = gql`
  query ($messageId: Int!, $includeData: Boolean, $asUserId: Int) {
    messageAttachments(messageId: $messageId, includeData: $includeData, asUserId: $asUserId) {
      name
      mimeType
      base64
    }
  }
`;

export const MARK_CONVERSATION_READ = gql`
  mutation ($conversationId: Int!) {
    markConversationRead(conversationId: $conversationId)
  }
`;

export const GET_ALL_THERAPIST_CHILD_PARENTS = gql`
  query {
    therapistChildParents {
      adultId
      childId
    }
  }
`;

export const THERAPIST_GET_CARE_TEAM = gql`
  query {
    careTeam {
      careCoordinationEnabled
      users {
        id
        firstName
        lastName
        accountType
      }
    }
  }
`;

export const SEND_MESSAGE_BATCH = gql`
  mutation ($message: String, $options: [ConversationOptions]) {
    sendMessageBatch(message: $message, options: $options)
  }
`;

export const GET_ACTIVITY_FEED = gql`
  query ($userIds: [Int]!, $offset: Int, $limit: Int) {
    activityFeed(userIds: $userIds, offset: $offset, limit: $limit) {
      date
      title
      subtitle
      icon
      image
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation ($userIds: [Int]!) {
    createConversation(userIds: $userIds)
  }
`;

export const THERAPIST_ADD_CONNECTION = gql`
  mutation ($connectUserEmail: String!, $connectToCareMemberUserId: Int!) {
    addConnectionToCareTeamMember(
      connectToCareMemberUserId: $connectToCareMemberUserId
      connectUserEmail: $connectUserEmail
      linkUninvitedUser: true
    ) {
      status
      message
    }
  }
`;

export const DELETE_PENDING_ACTIONS = gql`
  mutation ($conversationIds: [Int]!) {
    deletePendingActions(conversationIds: $conversationIds)
  }
`;

export const GET_FAMILIES = gql`
  query ($ids: [Int]!) {
    families(ids: $ids) {
      id
      users {
        id
        firstName
        lastName
        accountType
      }
    }
  }
`;

export const LEAVE_CONVERSATION = gql`
  mutation ($conversationId: Int) {
    leaveConversation(conversationId: $conversationId)
  }
`;

export const GET_CHILDREN_BY_PARENT_EMAIL = gql`
  query ($parentEmail: String!) {
    childrenByParentEmail(parentEmail: $parentEmail) {
      id
      firstName
      lastName
      therapists {
        user {
          id
          firstName
          lastName
        }
      }
      parentCoaches {
        user {
          id
          firstName
          lastName
        }
      }
      careCoordinators {
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const REMOVE_CONNECTION = gql`
  mutation ($disconnectCareMemberUserId: Int, $disconnectAllCareTeamMembers: Boolean, $disconnectUserId: Int!) {
    removeConnectionToCareTeamMember(
      disconnectCareMemberUserId: $disconnectCareMemberUserId
      disconnectAllCareTeamMembers: $disconnectAllCareTeamMembers
      disconnectUserId: $disconnectUserId
    )
  }
`;

export const DELETE_MESSAGE = gql`
  mutation ($messageId: Int!) {
    deleteMessage(messageId: $messageId)
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation CreateAppointment(
    $appointmentTypeId: String!
    $attendeeIds: [Int!]!
    $date: String!
    $contactMethod: String!
  ) {
    createAppointment(
      appointmentTypeId: $appointmentTypeId
      attendeeIds: $attendeeIds
      date: $date
      contactMethod: $contactMethod
    ) {
      id
      date
      length
      contactMethod
      attendees {
        id
        firstName
        lastName
        familyId
        accountType
      }
      appointmentType {
        id
        name
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPES = gql`
  {
    appointmentTypes {
      id
      name
      length
      isGroup
      contactMethods
      applicableCareTeamMemberIds
      pricingInfo {
        priceInCents
      }
    }
  }
`;

export const GET_AVAILABLE_DAYS = gql`
  query (
    $dateInMonth: String!
    $timeZone: String
    $userIds: [Int]!
    $appointmentTypeId: String!
    $contactMethod: String!
    $recurringInterval: RecurringAppointmentInterval
    $recurringCount: Int
  ) {
    availableDays(
      dateInMonth: $dateInMonth
      timeZone: $timeZone
      userIds: $userIds
      appointmentTypeId: $appointmentTypeId
      contactMethod: $contactMethod
      recurringInterval: $recurringInterval
      recurringCount: $recurringCount
    ) {
      availableDates
    }
  }
`;

export const GET_AVAILABLE_TIME_SLOTS = gql`
  query (
    $startDate: String!
    $endDate: String!
    $timeZone: String
    $userIds: [Int]!
    $appointmentTypeId: String!
    $contactMethod: String!
    $recurringInterval: RecurringAppointmentInterval
    $recurringCount: Int
  ) {
    availableTimeSlots(
      startDate: $startDate
      endDate: $endDate
      timeZone: $timeZone
      userIds: $userIds
      appointmentTypeId: $appointmentTypeId
      contactMethod: $contactMethod
      recurringInterval: $recurringInterval
      recurringCount: $recurringCount
    ) {
      userId
      startTime
    }
  }
`;

export const GET_USER = gql`
  {
    me {
      id
      accountType
      firstName
      lastName
      email
    }
  }
`;

export const GET_FAMILY_MEMBERS = gql`
  {
    me {
      id
      accountType
      firstName
      lastName
      email
      dateOfBirth
    }
    familyMembers {
      id
      firstName
      lastName
      accountType
      dateOfBirth
    }
  }
`;

export const GET_FORMS = gql`
  query getForms($userIds: [Int!], $ids: [String!], $status: FormStatus) {
    forms(userIds: $userIds, ids: $ids, status: $status) {
      id
      user {
        id
        firstName
      }
      formTemplate {
        id
        name
      }
      status
    }
  }
`;

export const CREATE_EHR_API_KEYS = gql`
  mutation createEhrApiKeys($userIds: [Int!]) {
    createEhrApiKeys(userIds: $userIds) {
      apiKey
      userId
      ehrUserId
    }
  }
`;

export const GET_GUIDING_STARS = gql`
  query getGuidingStars($userIds: [Int!]!) {
    guidingStars(userIds: $userIds) {
      id
      userId
      description
    }
  }
`;

export const GET_CARE_TEAM_MATCHES = gql`
  query careTeamMemberMatches($userIds: [Int!]!) {
    careTeamMemberMatches(userIds: $userIds) {
      user {
        id
        firstName
        lastName
        accountType
        familyId
      }
      careTeamMember {
        id
        firstName
        lastName
        accountType
        credentialing
        profileImageUrl
      }
    }
  }
`;

export const CAREGIVER_GET_CARE_TEAM_MATCHES = gql`
  query careTeamMemberMatches($userIds: [Int!]!) {
    familyMembers {
      id
      firstName
      lastName
      accountType
    }
    careTeamMemberMatches(userIds: $userIds) {
      user {
        id
        firstName
        lastName
        accountType
        familyId
      }
      careTeamMember {
        id
        firstName
        lastName
        accountType
        credentialing
        profileImageUrl
      }
    }
  }
`;

export const SEND_CARE_TEAM_MEMBER_MATCHES_EMAIL = gql`
  mutation sendCareTeamMemberMatchesEmail($contextUserId: Int!, $emailRecipientUserIds: [Int!]!) {
    sendCareTeamMemberMatchesEmail(contextUserId: $contextUserId, emailRecipientUserIds: $emailRecipientUserIds) {
      operationName
      mutatedObjects {
        id
        data
      }
      errors
    }
  }
`;

export const SET_CARE_TEAM_MEMBER_MATCHES = gql`
  mutation setCareTeamMemberMatches(
    $userId: Int!
    $careTeamMemberIds: [Int!]!
    $retainCareCoordinatorMatches: Boolean
  ) {
    setCareTeamMemberMatches(
      userId: $userId
      careTeamMemberIds: $careTeamMemberIds
      retainCareCoordinatorMatches: $retainCareCoordinatorMatches
    ) {
      user {
        id
        firstName
        lastName
        accountType
        familyId
      }
      careTeamMember {
        id
        firstName
        lastName
        accountType
      }
    }
  }
`;
