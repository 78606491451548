import { StyleSheet, css } from "aphrodite";
import React from "react";
import Text from "../core/Text";
import { MANA_BLUE, MANA_BLUE_LIGHT } from "../../theme/colors";

type Step = {
  number: number;
  title: React.ReactNode;
  description?: string;
  listItems?: string[];
};

type Props = {
  steps: Step[];
};

export default function VerticalProgress({ steps }: Props) {
  return (
    <div className={css(styles.wrapper)}>
      {steps.map((step, i) => (
        <div key={step.number} className={css(styles.stepContainer)}>
          <div className={css(styles.iconWrapper)}>
            <div className={css(styles.icon)}>
              <span className={css(styles.iconNumber)}>{step.number}</span>
            </div>
            {i < steps.length - 1 && <div className={css(styles.line)} />}
          </div>
          <div className={css(styles.textWrapper)}>
            <Text>{step.title}</Text>
            {step.description && <p className={css(styles.description)}>{step.description}</p>}
            {step.listItems && (
              <ul className={css(styles.list)}>
                {step.listItems.map((item, idx) => (
                  <li key={idx}>
                    <Text style={{ display: "list-item" }}>{item}</Text>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: 600,
    margin: "0 auto",
    boxSizing: "border-box"
  },
  stepContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 20,
    width: "100%"
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    marginRight: 10,
    flexShrink: 0
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: MANA_BLUE_LIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    flexShrink: 0
  },
  iconNumber: {
    color: MANA_BLUE,
    fontSize: 24,
    fontWeight: "bold"
  },
  line: {
    width: 2,
    height: 40,
    backgroundColor: "transparent",
    borderLeft: "3px dotted #F4D35E",
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translateX(-50%)"
  },
  textWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    maxWidth: "calc(100% - 60px)",
    wordBreak: "break-word",
    overflowWrap: "break-word"
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    margin: 0,
    lineHeight: 1.4
  },
  description: {
    fontSize: 14,
    color: "#1A2579",
    marginTop: 4,
    lineHeight: 1.4
  },
  list: {
    marginTop: 8,
    paddingLeft: 20,
    listStyleType: "disc"
  },
  "@media (max-width: 480px)": {
    wrapper: {
      padding: "0 12px"
    },
    title: {
      fontSize: 14
    },
    description: {
      fontSize: 12
    },
    icon: {
      width: 36,
      height: 36
    },
    iconNumber: {
      fontSize: 20
    }
  }
});
