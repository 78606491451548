import React, { ReactNode } from "react";
import { StyleSheet, css } from "aphrodite";
import Text from "../core/Text";
import Flex from "../core/Flex";

type Props = {
  provider?: { firstName: string; id: number; profileImageUrl?: string };
  innerText: string;
  subTitle?: ReactNode;
  children?: ReactNode;
};

export default function ProviderCard({ provider, innerText, subTitle, children }: Props) {
  const profileImageFolder = process.env.NODE_ENV === "development" || !provider ? "test" : provider.id;
  const alternateProfileImageUrl = `https://storage.googleapis.com/manatee-production-app-content/images/profiles/${profileImageFolder}/profile.png`;

  return (
    <Flex style={{ backgroundColor: "#F2F2F2", marginTop: 20, padding: 20, borderRadius: 15 }}>
      <Flex row>
        {provider && provider.profileImageUrl && provider.profileImageUrl.startsWith("https://") ? (
          <img src={provider.profileImageUrl} className={css(styles.profileImage)} alt="profile picture" />
        ) : (
          <img src={alternateProfileImageUrl} className={css(styles.profileImage)} alt="careCoordinatorProfile" />
        )}
        <Flex center left style={{ paddingLeft: 10 }}>
          <>
            {provider?.firstName && (
              <Text weight={700} size={16} theme="manaBlueDark">
                {provider.firstName}
              </Text>
            )}
            <Text weight={400} size={14} theme="manaBlueDark">
              {subTitle ? subTitle : "Care coordinator"}
            </Text>
          </>
        </Flex>
      </Flex>
      <Text weight={400} style={{ paddingTop: 10 }}>
        {innerText}
      </Text>
      {children}
    </Flex>
  );
}

const styles = StyleSheet.create({
  profileImage: {
    width: 40,
    height: 40,
    objectFit: "cover",
    borderRadius: "50%"
  }
});
