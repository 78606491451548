import React from "react";
import CareScreener from "../components/care-screener/CareScreener";
import { CareScreenerContextProvider } from "../context/CareScreenerContext";

const TYPEFORM_ID = process.env.REACT_APP_CONSULT_TYPE_FORM_ID as string;
const TYPEFORM_PATH = "consult";
const CONTINUE_TO_BOOK_CONSULT_QUESTION_REF = "endScreen-fieldRef";
const CONTINUE_TO_BOOK_CONSULT_QUESTION_REF_ALT = "phoneNumber-fieldRef";

export default function ConsultPage() {
  return (
    <CareScreenerContextProvider>
      <CareScreener
        typeformId={TYPEFORM_ID}
        typeformPath={TYPEFORM_PATH}
        allowAppointmentScheduling={true}
        accountCreationPredicate={{
          tfQuestionRefShown: CONTINUE_TO_BOOK_CONSULT_QUESTION_REF,
          tfQuestionRefShownAlt: CONTINUE_TO_BOOK_CONSULT_QUESTION_REF_ALT
        }}
        insuranceEligiblityCheckEnabled={true}
      />
    </CareScreenerContextProvider>
  );
}
