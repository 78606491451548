import React from "react";
import Flex from "../core/Flex";
import Button from "../Button";
import Text from "../core/Text";
import { MANA_BLUE, MANA_BLUE_EXTRA_LIGHT } from "../../theme/colors";
import Span from "../core/Span";

export type MatchedCareProvider = {
  id: number;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  credentialing: string;
};

type Props = {
  careProvider: MatchedCareProvider;
  onCareProviderSelected: () => void;
  selectButtonTitle: string;
  nextAvailable?: string;
};

export default function MatchedCareProviderSelector({
  careProvider,
  onCareProviderSelected,
  selectButtonTitle,
  nextAvailable
}: Props) {
  return (
    <Flex center style={{ backgroundColor: MANA_BLUE_EXTRA_LIGHT, borderRadius: 12, padding: 12 }}>
      <Flex row style={{ padding: 10, width: "100%", alignItems: "flex-start" }}>
        <Flex style={{ width: 72, height: 72 }}>
          {careProvider.profileImageUrl && careProvider.profileImageUrl.startsWith("https://") ? (
            <img src={careProvider.profileImageUrl} alt={"profile picture"} style={{ borderRadius: "50%" }} />
          ) : (
            <div style={{ height: 72, width: 72, widows: 72, borderRadius: "50%", backgroundColor: "#D3D3D3" }}></div>
          )}
        </Flex>
        <Flex column left center style={{ padding: 10, width: "auto" }}>
          <Text theme="manaBlueDark" weight={700} size={16} style={{ lineHeight: 1.5 }}>
            {careProvider.firstName} {careProvider.lastName} <Span weight={400}>{careProvider.credentialing}</Span>
          </Text>
          {!!nextAvailable && (
            <Text weight={400} size={14} style={{ lineHeight: 1.3 }}>
              🗓️ Next: {nextAvailable}
            </Text>
          )}
          <Text>
            <a
              target="_blank"
              href={`https://www.getmanatee.com/therapist/${careProvider.firstName}-${careProvider.lastName}`
                .replace(/\s+/g, "-")
                .toLowerCase()}
              style={{ color: MANA_BLUE }}
            >
              View profile
            </a>
          </Text>
        </Flex>
      </Flex>
      <Flex style={{ width: "100%" }}>
        <Button theme="manaBlue" title={selectButtonTitle || "select"} onPress={onCareProviderSelected} />
      </Flex>
    </Flex>
  );
}
