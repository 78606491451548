import { useCallback, useEffect, useRef, useState } from "react";

export type ArrayIterator<T> = {
  current: T | undefined;
  prev: () => void;
  next: () => void;
  hasPrev: () => boolean;
  hasNext: () => boolean;
  peekPrev: () => T | undefined;
  peekNext: () => T | undefined;
  index: number;
};

export default function useArrayIterator<T>(values: T[], startingIndex = 0): ArrayIterator<T> {
  const index = useRef(startingIndex);

  const [current, setCurrent] = useState(values?.length ? values[startingIndex] : undefined);

  useEffect(() => {
    index.current = startingIndex;
    if (values?.length) {
      setCurrent(values[index.current]);
    } else {
      setCurrent(undefined);
    }
  }, [values, startingIndex]);

  const hasPrev = useCallback(() => {
    return index.current > 0;
  }, []);

  const hasNext = useCallback(() => {
    return values?.length > 0 && index.current < values.length - 1;
  }, [values]);

  const next = useCallback(() => {
    if (hasNext()) {
      index.current += 1;
      setCurrent(values[index.current]);
    }
  }, [values, setCurrent, hasNext]);

  const prev = useCallback(() => {
    if (hasPrev()) {
      index.current -= 1;
      setCurrent(values[index.current]);
    }
  }, [hasPrev, setCurrent, values]);

  const peekPrev = useCallback(() => {
    if (values.length && index.current > 0) {
      return values[index.current - 1];
    }
    return undefined;
  }, [values]);

  const peekNext = useCallback(() => {
    if (values.length && index.current < values.length - 1) {
      return values[index.current + 1];
    }
    return undefined;
  }, [values]);

  return { current, prev, next, hasPrev, hasNext, peekPrev, peekNext, index: index.current };
}
