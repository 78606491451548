import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import "./AppointmentScheduler.scss";
import CalendarIcon from "../../assets/calendar-over-blue-blotch.png";
import useAppointmentType from "../../hooks/appointments/useAppointmentType";
import { useGlobalContext } from "../../context/GlobalComponentsContext";
import { useMutation, useQuery } from "@apollo/client";
import { GET_FAMILY_MEMBERS, CREATE_APPOINTMENT } from "../../queries";
import AppointmentDateTimePicker from "./AppointmentDateTimePicker";
import { StyleSheet, css } from "aphrodite";
import { logEvent } from "../../actions";
import { reportError } from "../../utils/errorUtils";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import Flex from "../core/Flex";
import { User } from "../../model/userModels";
import { AppointmentType } from "../../model/appointmentModels";
import Spinner from "../Spinner";

type AppointmentSchedulerContextType = {
  attendee: User;
  appointmentType: AppointmentType;
  appointmentContactMethod: string;
  careTeamMemberIds: number[];
  selectedTimeSlot: SelectedTimeSlot | undefined;
  setSelectedTimeSlot: Dispatch<SetStateAction<SelectedTimeSlot | undefined>>;
};

type SelectedTimeSlot = { startTime: string; userId: number };

export const AppointmentSchedulerContext = React.createContext<AppointmentSchedulerContextType | undefined>(undefined);

export default function AppointmentScheduler({
  appointmentId,
  appointmentContactMethod = "Phone Call",
  appointmentUserId,
  onAppointmentScheduled,
  careTeamMemberIds,
  availabilityRecurringInterval,
  availabilityRecurringCount,
  hideCalendarIcon = false,
  headerComponent = <></>,
  style = {}
}) {
  const [processing, setProcessing] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<SelectedTimeSlot | undefined>();

  const { showError } = useGlobalContext();

  const { appointmentType, loading: apptLoading } = useAppointmentType(appointmentId);
  const { data, loading: userLoading, error } = useQuery(GET_FAMILY_MEMBERS);

  useEffect(() => {
    if (error) {
      reportError(error);
      showError();
    }
  }, [error, showError]);

  const user = useMemo(() => data?.me, [data]);
  const attendee = useMemo(
    () => (!appointmentUserId ? user : data && data.familyMembers.find(fm => fm.id === appointmentUserId)),
    [appointmentUserId, user, data]
  );

  const [createAppointmentMutation] = useMutation(CREATE_APPOINTMENT);
  const { widthStyle } = useCareScreenerWidth();

  const handleCreateAppointment = useCallback(async () => {
    try {
      setProcessing(true);
      if (selectedTimeSlot) {
        const result = await createAppointmentMutation({
          variables: {
            appointmentTypeId: appointmentType.id,
            attendeeIds: [attendee.id, selectedTimeSlot.userId],
            date: selectedTimeSlot.startTime,
            contactMethod: appointmentContactMethod
          }
        });

        const appointment = result.data.createAppointment;
        onAppointmentScheduled(appointment);
        logEvent("consultAppointmentScheduled");
      }
    } catch (error) {
      reportError(error);
      showError();
    } finally {
      setProcessing(false);
    }
  }, [
    createAppointmentMutation,
    appointmentType?.id,
    attendee?.id,
    selectedTimeSlot,
    appointmentContactMethod,
    onAppointmentScheduled,
    showError
  ]);

  if (apptLoading || userLoading || processing) {
    return (
      <Flex center style={{ marginBottom: 100, marginTop: 100 }}>
        <Spinner inline />
      </Flex>
    );
  }

  return (
    <AppointmentSchedulerContext.Provider
      value={{
        attendee,
        appointmentType,
        appointmentContactMethod,
        careTeamMemberIds,
        selectedTimeSlot,
        setSelectedTimeSlot
      }}
    >
      <Flex flex center className={css(styles.container)} style={{ ...widthStyle, minWidth: 200, ...style }}>
        {!hideCalendarIcon && <img src={CalendarIcon} className={css(styles.calendarIcon)} />}
        {!!appointmentType && (
          <AppointmentDateTimePicker
            onContinuePressed={handleCreateAppointment}
            headerComponent={headerComponent}
            availabilityRecurringInterval={availabilityRecurringInterval}
            availabilityRecurringCount={availabilityRecurringCount}
          />
        )}
      </Flex>
    </AppointmentSchedulerContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
    marginTop: "5%",
    flex: 1,
    paddingBottom: 30,
    position: "relative",
    backgroundColor: "#FFFFFF"
  },
  calendarIcon: {
    width: 80,
    height: 80,
    aspectRatio: 1,
    position: "absolute",
    zIndex: 1000,
    top: 0,
    left: 0
  }
});
