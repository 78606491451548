import React from "react";
import { StyleSheet, css } from "aphrodite";
import FamilyImage from "../../assets/consult/consult-booked-illustration.png";
import AppointmentsIcon from "../../assets/consult/appointments.png";
import HeartIcon from "../../assets/consult/heart.png";
import MessageIcon from "../../assets/consult/message.png";
import ManateeImage from "../../assets/consult/manatee.png";
import EightyFourPercentImage from "../../assets/consult/eighty-four-percent.png";
import SeventyEightPercentImage from "../../assets/consult/seventy-eight-percent.png";
import OneHundredSeventyFiveDollarsImage from "../../assets/consult/one-hundred-seventy-five-dollars.png";
import OneHundredThirtyFiveDollarsImage from "../../assets/consult/one-hundred-thirty-five-dollars.png";
import NinetyNineDollarsImage from "../../assets/consult/ninety-nine-dollars.png";
import useQueryUser from "../../hooks/useQueryUser";
import moment from "moment-timezone";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import Flex from "../core/Flex";

export default function BookedConsult({ appointment }) {
  const { user } = useQueryUser();
  const { widthStyle } = useCareScreenerWidth();
  return (
    <Flex center className={css(styles.container)} style={widthStyle}>
      <img src={FamilyImage} className={css(styles.image)} />
      <p className={css(styles.manaBlue, styles.textCentered)}>
        <span>{`Way to go${user?.firstName ? ", " + user.firstName : ""}! `}</span>
        <br />
        <span>Your consultation is confirmed for</span>
      </p>
      <div className={css(styles.smallVerticalSpacer)} />
      {!!appointment?.date && (
        <p className={css(styles.manaDarkBlue, styles.textCentered)}>{`${moment(appointment.date).format(
          "LL"
        )} at ${moment(appointment.date).format("LT")}`}</p>
      )}
      <div className={css(styles.smallVerticalSpacer)} />
      <p className={css(styles.gray, styles.textCentered)}>
        You should be receiving a confirmation email and a calendar invite shortly!
      </p>
      <div className={css(styles.verticalSpacer)} />
      <p className={css(styles.manaBlue)}>What’s next?</p>
      <p className={css(styles.manaDarkBlue, styles.textCentered)}>Log into your account on the Manatee app</p>
      <div className={css(styles.smallVerticalSpacer)} />
      <a className={css(styles.button, styles.downloadButton)} href="https://www.getmanatee.com/get-the-app">
        Download the app
      </a>
      <div className={css(styles.verticalSpacer)} />
      <p className={css(styles.gray)}>Use your email and your new password to:</p>
      <div>
        <div className={css(styles.row)}>
          <img src={AppointmentsIcon} className={css(styles.icon, styles.appointmentsIcon)} />
          <p className={css(styles.manaBlue, styles.textCentered)}>See your appointment information</p>
        </div>
        <div className={css(styles.row)}>
          <img src={MessageIcon} className={css(styles.icon)} />
          <p className={css(styles.manaBlue, styles.textCentered)}>Message with your care coordinator</p>
        </div>
        <div className={css(styles.row)}>
          <img src={HeartIcon} className={css(styles.icon)} />
          <p className={css(styles.manaBlue, styles.textCentered)}>Get a head start on your care journey</p>
        </div>
      </div>
      <div className={css(styles.verticalSpacer)} />
      <p className={css(styles.manaBlue, styles.textCentered)}>You have questions? We have answers!</p>
      <p className={css(styles.manaDarkBlue, styles.textCentered)}>These are the top 3 questions we get.</p>
      <div className={css(styles.verticalSpacer)} />
      <p className={css(styles.manaBlue, styles.textCentered, styles.hilite)}>
        “Is virtual therapy just as good as in-person therapy?”
      </p>
      <div>
        <div className={css(styles.row, styles.narrow)}>
          <img className={css(styles.statsImage)} src={EightyFourPercentImage} />
          <p className={css(styles.gray)}>
            of parents who accessed teletherapy for their child said they had a positive experience.
          </p>
        </div>
        <div className={css(styles.row, styles.narrow)}>
          <img className={css(styles.statsImage)} src={SeventyEightPercentImage} />
          <p className={css(styles.gray)}>
            reported significant improvement in their child’s symptoms since starting therapy.
          </p>
        </div>
      </div>
      <div className={css(styles.verticalSpacer)} />
      <p className={css(styles.manaBlue, styles.textCentered, styles.hilite)}>“How much do your sessions cost?”</p>
      <p className={css(styles.gray, styles.textCentered)}>
        Our consultations are 100% free and a great first step to get started, so great job!
      </p>
      <div>
        <div className={css(styles.row, styles.narrow)}>
          <img className={css(styles.statsImage)} src={OneHundredSeventyFiveDollarsImage} />
          <p className={css(styles.gray)}>
            50-minute family intake, which includes a full mental health assessment and personalized care plan.
          </p>
        </div>
        <div className={css(styles.row, styles.narrow)}>
          <img className={css(styles.statsImage)} src={OneHundredThirtyFiveDollarsImage} />
          <p className={css(styles.gray)}>30-45 minute child, parent or family therapy session.</p>
        </div>
        <div className={css(styles.row, styles.narrow)}>
          <img className={css(styles.statsImage)} src={NinetyNineDollarsImage} />
          <p className={css(styles.gray)}>30-45 minute parent coaching session.</p>
        </div>
      </div>
      <div className={css(styles.verticalSpacer)} />
      <p className={css(styles.manaBlue, styles.hilite)}>“Do you take insurance?”</p>
      <p className={css(styles.gray)}>
        Yes! We are an in-network provider with lots of insurance companies (United Healthcare, Optum, Cigna and Aetna)
        just let us know during your consultation and we’ll check for you.
        <br />
        <br />
        We also provide your family with a “superbill” you can submit to your insurance for reimbursement (usually
        you’ll get about 50-70% reimbursed).
        <br />
        <br />
        We do not take medicare or medicaid, but we are committed to making our care affordable and can offer services
        on a sliding scale (reducing cost to $75-$125 for a therapy session).
      </p>
      <div className={css(styles.verticalSpacer)} />
      <p className={css(styles.manaBlue)}>Want to know more?</p>
      <a className={css(styles.button)} href="https://www.getmanatee.com/faqs">
        Read our FAQs
      </a>
      <img src={ManateeImage} className={css(styles.image)} />
      <p className={css(styles.manaBlue, styles.textCentered)}>Still need some clarification? We’ve got you.</p>
      <a className={css(styles.button)} href="https://www.getmanatee.com/contact-us">
        Contact Manatee Care
      </a>
    </Flex>
  );
}

const smallScreen = window.innerWidth < 768;

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
    marginBottom: 50,
    padding: "5%",
    paddingTop: 10,
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15
  },
  row: {
    display: "flex",
    flexDirection: smallScreen ? "column" : "row",
    alignItems: "center",
    minWidth: smallScreen ? 200 : 500,
    maxWidth: smallScreen ? 300 : 600
  },
  image: {
    width: 225,
    height: 225,
    objectFit: "contain"
  },
  statsImage: {
    width: smallScreen ? 150 : 100,
    height: smallScreen ? 150 : 100,
    paddingRight: 10,
    objectFit: "contain"
  },
  narrow: {
    width: 500
  },
  appointmentsIcon: {
    transform: "scale(1.25);"
  },
  icon: {
    width: 90,
    height: 80,
    marginRight: smallScreen ? 0 : 20,
    objectFit: "contain"
  },
  manaBlue: {
    color: "#2562D8",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 1.4,
    paddingBottom: 7
  },
  manaDarkBlue: {
    color: "#1A2579",
    fontWeight: 700,
    fontSize: 26,
    lineHeight: 1
  },
  gray: {
    fontSize: 16,
    lineHeight: 2,
    color: "#636C8C",
    fontWeight: 400,
    textAlign: smallScreen ? "center" : "start"
  },
  textCentered: {
    textAlign: "center"
  },
  verticalSpacer: {
    height: 20
  },
  smallVerticalSpacer: {
    height: 14
  },
  button: {
    backgroundColor: "#BBD2FF",
    borderRadius: 15,
    fontSize: 16,
    color: "#2562D8",
    padding: 10,
    textAlign: "center",
    minWidth: 250,
    fontWeight: 600,
    textDecoration: "none"
  },
  downloadButton: {
    marginTop: 10,
    backgroundColor: "#2562D8",
    color: "#FFFFFF"
  },
  hilite: {
    backgroundColor: "#EDD666",
    paddingTop: 0,
    paddingBottom: 0
  }
});
