import React from "react";
import { StyleSheet, css } from "aphrodite";
import Flex from "../core/Flex";
import Text from "../core/Text";
import Span from "../core/Span";
import VerticalProgress from "./VerticalProgress";
import ProviderCard from "./ProviderCard";
import useCareScreenerWidth from "../../hooks/care-screener/useCareScreenerWidth";
import FamilyImage from "../../assets/consult/consult-booked-illustration.png";

export default function IntakeConfirmation({ progressComponent, careCoordinator }) {
  const { widthStyle } = useCareScreenerWidth();
  return (
    <Flex center column className={css(styles.container)} style={widthStyle}>
      {progressComponent}
      <img src={FamilyImage} className={css(styles.image)} />

      <Text size={32} weight={800} theme="manaBlueDark">
        Way to go! 👏
      </Text>
      <Text size={16} style={{ marginTop: 20 }}>
        To make sure your intake goes smoothly, please complete the below a minimum of 24 hours prior to your session:
      </Text>
      <VerticalProgress
        steps={[
          {
            number: 1,
            title: (
              <>
                Download the{" "}
                <Span weight={800} theme="manaBlueDark">
                  Manatee app
                </Span>{" "}
                📱
              </>
            )
          },
          {
            number: 2,
            title: (
              <>
                Add your{" "}
                <Span weight={800} theme="manaBlueDark">
                  child/co-parent
                </Span>{" "}
                🏠
              </>
            )
          },
          {
            number: 3,
            title: (
              <>
                Complete{" "}
                <Span weight={800} theme="manaBlueDark">
                  'Your to-dos'
                </Span>{" "}
                on the home screen ✅
              </>
            ),
            listItems: ["Consent forms", "Clinical Evaluation", "Credit card information"]
          }
        ]}
      />
      <Text>We will have to reschedule your session if this is not complete 24 hours in advance.</Text>
      <ProviderCard provider={careCoordinator} innerText="">
        <Text>
          <Span weight={800} theme="manaBlueDark">
            If you have any questions,
          </Span>{" "}
          just reach out to me on the app or text me at{" "}
          <a href="sms:2135584348" style={{ textDecoration: "none" }}>
            <Span>(213) 558-4348.</Span>
          </a>
        </Text>
      </ProviderCard>
    </Flex>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 50,
    marginBottom: 50,
    padding: "5%",
    paddingTop: 10,
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15
  },
  image: {
    width: 225,
    height: 225,
    objectFit: "contain"
  }
});
