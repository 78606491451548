import { useContext, useMemo, useState } from "react";
import { AccountType } from "../../model/userModels";
import { Appointment } from "../../model/appointmentModels";
import Text from "../core/Text";
import { UserContext } from "../../context/UserContext";
import Flex from "../core/Flex";
import React from "react";
import moment from "moment";
import ProviderCard from "../care-screener/ProviderCard";
import { HorizontalLine } from "../care-screener/ProviderSelection";
import Button from "../Button";
import accentOne from "../../assets/care-screener/accents-one.png";
import accentTwo from "../../assets/care-screener/accents-two.png";
import { StyleSheet, css } from "aphrodite";
import { MatchedCareProvider } from "./MatchedCareProviderSelector";
import { CareScreenerContext } from "../../context/CareScreenerContext";

type Props = {
  appointment: Appointment;
  onContinueButtonPressed: () => void;
  onScheduleNextClientButtonPress: () => void;
  matchedCareProvider?: MatchedCareProvider;
};

const generateBlurb = createBlurbGenerator();

export default function BookedIntake({
  appointment,
  onContinueButtonPressed,
  onScheduleNextClientButtonPress,
  matchedCareProvider
}: Props) {
  const { user } = useContext(UserContext) || {};
  const { candidateIterator } = useContext(CareScreenerContext) || {};

  const [currentCandidate] = useState(candidateIterator?.current);
  const [nextCandidate] = useState(candidateIterator?.peekNext());
  const careTeamMember = appointment.attendees.find(u => AccountType.isCareTeamMember(u.accountType));

  const preciseAptTime = useMemo(() => {
    const startTime = moment(appointment.date).format("h:mma");
    const endTime = moment(appointment.date).add(1, "hour").format("h:mma");
    return `${startTime} - ${endTime}`;
  }, [appointment]);

  return (
    <Flex className={css(styles.container)}>
      <Text center theme="manaBlueDark" size={32} weight={700} style={{ paddingBottom: 20 }}>
        Session scheduled! ✅
      </Text>
      <div style={{ position: "relative" }}>
        <img src={accentOne} style={{ position: "absolute", width: 24, top: -12, left: -14 }} />
        <Flex>
          {" "}
          <Text size={24} theme="manaBlue" weight={700} style={{ marginTop: 5 }}>
            🗓️ {currentCandidate?.user.firstName}'s Intake
          </Text>
          <Text theme="manaBlueDark" weight={700} style={{ marginTop: 5 }}>
            {moment(appointment.date).format("MMMM D, YYYY")}
          </Text>
          <Text theme="manaBlueDark" weight={700} style={{ marginTop: 5 }}>
            {preciseAptTime}
          </Text>
        </Flex>
        <img src={accentTwo} style={{ position: "absolute", width: 24, bottom: 0, right: 0 }} />
      </div>
      {careTeamMember && (
        <ProviderCard
          provider={matchedCareProvider || careTeamMember}
          innerText={generateBlurb({
            childName: currentCandidate?.user.firstName,
            parentName: user?.firstName
          })}
          subTitle={`${currentCandidate?.user.firstName}'s Therapist`}
        />
      )}
      {nextCandidate && (
        <>
          <HorizontalLine />
          <Flex column>
            <Text style={{ alignSelf: "center", margin: 12 }}>Next</Text>
            <Button
              theme="manaBlue"
              onPress={onScheduleNextClientButtonPress}
              title={`select ${nextCandidate.user.firstName}'s therapist`}
            />
          </Flex>
        </>
      )}
      {(!nextCandidate || !currentCandidate) && (
        <Button theme="manaBlue" title="finish up" onPress={onContinueButtonPressed} style={{ marginTop: 20 }} />
      )}
    </Flex>
  );
}

function createBlurbGenerator() {
  const blurbs = [
    `Hi, {{parentName}}! I'm looking forward to working with {{childName}} and your family.`,
    `Hello, {{parentName}}! I'm excited to start working with {{childName}} and supporting your family.`,
    `Hi, {{parentName}}! I'm thrilled to begin therapy with {{childName}} and collaborate with your family.`
  ];

  let currentIndex = 0;

  const shuffledBlurbs = blurbs.sort(() => Math.random() - 0.5);

  return function generateBlurb({ childName, parentName }): string {
    const blurb = shuffledBlurbs[currentIndex]
      .replace("{{parentName}}", parentName)
      .replace("{{childName}}", childName);

    currentIndex = (currentIndex + 1) % shuffledBlurbs.length;

    return blurb;
  };
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 50,
    padding: "5%",
    paddingTop: 10,
    backgroundColor: "#FFFFFF",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: 15
  }
});
