import { StyleSheet, css } from "aphrodite";
import React from "react";
import { MANA_BLUE, MANA_BLUE_LIGHT } from "../../theme/colors";

type Props = {
  steps: string[];
  index: number;
};

export default function ProgressHeader({ steps, index }: Props) {
  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.line)} />
      <div className={css(styles.row)}>
        {steps.map((name: string, i: number) => (
          <ProgressStep key={name} number={i + 1} isActive={index === i} name={name} isCompleted={i < index} />
        ))}
      </div>
    </div>
  );
}

type ProgressStepProps = {
  number: number;
  isActive?: boolean;
  isCompleted?: boolean;
  name: string;
};

export function ProgressStep({ number, isActive, isCompleted, name }: ProgressStepProps) {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.iconContainer)}>
        <svg className={css(styles.icon)} viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="20"
            cy="20"
            r="18"
            fill={isCompleted ? MANA_BLUE_LIGHT : isActive ? MANA_BLUE : MANA_BLUE_LIGHT}
          />
          {isCompleted && (
            <path
              d="M17.5 21.5l-3-3-1.5 1.5 4.5 4.5 9-9-1.5-1.5-7.5 7.5z"
              fill={MANA_BLUE}
              stroke={MANA_BLUE}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          )}
        </svg>
        {!isCompleted && <p className={css([styles.iconNumber, isActive ? styles.white : styles.blue])}>{number}</p>}
      </div>
      <p className={css(styles.name)}>{name}</p>
    </div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: "relative",
    width: "100%"
  },
  line: {
    position: "absolute",
    top: 22,
    left: "17%",
    right: "17%",
    height: 20,
    opacity: 0.5,
    zIndex: 1,
    borderTop: "5px dotted #F4D35E"
  },
  iconContainer: {
    position: "relative"
  },
  icon: {
    width: 30,
    height: 30
  },
  iconNumber: {
    position: "absolute",
    fontWeight: 800,
    fontSize: 18,
    margin: "auto",
    left: 0,
    right: 0,
    top: 5,
    bottom: 0,
    zIndex: 100
  },
  white: {
    color: "#FFFFFF"
  },
  blue: {
    color: "#2562D8"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
    maxWidth: 125
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "center",
    position: "relative",
    zIndex: 2
  },
  name: {
    color: "#1A2579",
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.2,
    marginTop: 4
  }
});
